import i18n from "i18n";

// NOTE: 預設是RESPONSE_CODE如果有繼承同KEY的句子會覆蓋
export const RESPONSE_CODE = {
  unauthorized: i18n.t('notLogin'),
  UNAUTHORIZATION: i18n.t('notLogin'),
  INCORRECT_PARAMS: i18n.t('incorrectRequest'),
  INCORRECT_ACCOUNT: i18n.t('INCORRECT_ACCOUNT'),
  INCORRECT_ACCOUNT_FORMAT: i18n.t('INCORRECT_ACCOUNT_FORMAT'),
  INCORRECT_ACCOUNT_OR_PASSWORD: `${i18n.t('incorrectAccountOrPassword')}`,
  INCORRECT_PASSWORD: `${i18n.t('INCORRECT_PASSWORD')}`,
  INCORRECT_PASSWORD_FORMAT: `${i18n.t('incorrectPasswordFormat')}`,
  INCORRECT_REPEAT_PASSWORD: i18n.t('INCORRECT_REPEAT_PASSWORD'),
  INCORRECT_ID: i18n.t('incorrectMemberAccount'),
  INCORRECT_NAME: i18n.t('INCORRECT_ACCOUNT'),
  INCORRECT_STATUS: i18n.t('incorrectStatus'),
  INCORRECT_DATE_FORMAT: i18n.t('incorrectDate'),
  INCORRECT_SMS_CODE: i18n.t('verificationCodeError'),
  NAME_ALEARDY_USE: i18n.t('duplicateName'),
  DUPLICATE_NAME: i18n.t('duplicateName'),
  DUPLICATE_ACCOUNT: i18n.t('DUPLICATE_ACCOUNT'),
  DUPLICATE_IDNumber: i18n.t('duplicateIdNumber'),
  DUPLICATE_Phone: i18n.t('duplicatePhone'),
  DUPLICATE_Email: i18n.t('duplicateEmail'),
  MEMBER_HAS_BEEN_KYC: i18n.t('memberHasBeenVerify'),
  MEMBER_HAS_NOT_BEEN_VERIFIED: i18n.t('memberHadNotVerify'),
  NOT_FIND_MEMBER: i18n.t('NOT_FIND_MEMBER'),
  NOT_FIND_AGENT: i18n.t('agentNotFound'),
  NOT_FIND_ACCOUNT: i18n.t('notFindAccount'),
  NOT_FOUND_MEMBER: i18n.t('noSuchMemberFound'),
  MEMBER_NOT_FOUND: i18n.t('noSuchMemberFound'),
  ITEM_NOT_FOUND: i18n.t('notFound'),
  AGENT_NOT_FOUND: i18n.t('noSuchAgentFound'),
  AGENTS_STATUS_CAUSE_CAN_NOT_DEPOSIT: i18n.t('AGENTS_STATUS_CAUSE_CAN_NOT_DEPOSIT'),
  AGENT_INSUFFICIENT_BALANCE: i18n.t('insufficientBalance'),
  INSUFFICIENT_BALANCE: i18n.t('insufficientBalance'),
  APPLY_AMOUNT_OVER_LIMIT: i18n.t('amountLimitExceeded'),
  PHONE_EXISTED: i18n.t('duplicatePhone'),
  PERMISSION_DENIED: i18n.t('permissionDenied'),
  ACCOUNT_BLOCKED: i18n.t('accountDisabled'),
  WITHDRAW_METHOD_PRE_APPROVED_BEFOURE_VERIFICATION: i18n.t('pendingWithdrawalMethodExists'),
  WITHDRAW_METHOD_BANK_ACCOUNT_EXISTED: i18n.t('accountNumberDuplicate'),
  WITHDRAW_METHOD_CRYPTO_ADDRESS_EXISTED: i18n.t('walletAddressDuplicate'),
  PAYMENT_MERCHANT_GOT_ERROR: i18n.t('PAYMENT_MERCHANT_GOT_ERROR'),
  PAYMENT_MERCHANT_MAINTAIN: i18n.t('PAYMENT_MERCHANT_MAINTAIN'),
  MORE_THAN_FILE_SIZE_MAX_LIMIT: i18n.t('imageExceedsSizeLimit'),
  WALLET_BALANCE_MAXIMUM_LIMIT: i18n.t('agentBalanceLimitReached'), // 站長調帳給代理 代理餘額已達上限
  BONUS_NOT_AVAILABLE: i18n.t('activityExpired'),
  RULE_FAIL: i18n.t('incorrectReferralBonusRule'),
  LEVEL_ALERADY_USE: i18n.t('cannotSetDuplicateLevels')
};

// 代理權限
export const agenti18n = ($sc: any = true, $s: any = { CommissionModule: '1' }) => {
  return [
    {
      key: '1', MenuName: i18n.t('team'), name: i18n.t('member'), rowSpan: 2, child: [
        { key: '0101', name: i18n.t('view') },
        { key: '0102', name: `${i18n.t('add')}／${i18n.t('edit')}` },
        { key: '0103', name: i18n.t('remark') },
        { key: '0104', name: i18n.t('transferAdd') },
        ...(!$sc ? [
          { key: '0105', name: i18n.t('transferDeduction') },
        ] : []),
        { key: '0106', name: i18n.t('cashFlowRecord') },
      ]
    },
    {
      key: '2', MenuName: i18n.t('team'), name: i18n.t('agent'), child: [
        { key: '0201', name: i18n.t('view') },
        { key: '0202', name: `${i18n.t('add')}／${i18n.t('edit')}` },
        { key: '0203', name: i18n.t('export') },
        { key: '0204', name: i18n.t('remark') },
      ]
    },
    {
      key: '3', MenuName: i18n.t('myWallet'), name: i18n.t('myWallet'), rowSpan: 1, child: [
        { key: '0301', name: i18n.t('view') },
        ...($sc ? [
          { key: '0302', name: i18n.t('depositing') },
        ] : []),
        { key: '0303', name: i18n.t('transfer') },
        ...($sc ? [
          { key: '0304', name: i18n.t('withdrawal') },
        ] : []),
      ]
    },
    {
      key: '4', MenuName: i18n.t('advanced'), name: i18n.t('memberRebateSettings'), rowSpan: 1, child: [
        { key: '0401', name: i18n.t('view') },
        { key: '0402', name: i18n.t('edit') },
      ]
    },
  ].filter(item => !($s.CommissionModule === '2' && item.key === '4'))
}