import { Button, Col, Form, Input, Row, Select, Table, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get } from 'services';
import { toFormatNumber as $f, $mobile, agentLevelWord, enumToOptions, timeL2S } from "utils/common";
import useAccount from 'hooks/account.hook';

// 成本報表
// NOTE: 同步於BO代理優惠統計

enum TAB {
  代理 = '1',
  會員 = '2',
  優惠 = '3'
}

enum BONUS_TYPE {
  dailyCheck = 1,
  // totalSignIn = 2,
  referralReward = 3,
  otherPromotions = 8,
  jackpot = 11,

  promotionAdjustment = 4,
  levelUpAmount = 5,
  
  weeklyBonus = 6,
  amountMonthly = 7,

  rebate = 9,
  redEnvelopePromotions = 12,
  signupBonus = 14
}

const PageMain: React.FC = () => {
  const { info, permissions: $p } = useAccount();

  const [activeKey, setActiveKey] = useState<string>(TAB.代理);
  const [form] = useForm();

  const items: TabsProps['items'] = [
    {
      key: TAB.代理,
      label: <div className="text-center w-5 ">{i18n.t('agent')}</div>
    },
    {
      key: TAB.會員,
      label: <div className="text-center w-5">{i18n.t('member')}</div>
    },
    {
      key: TAB.優惠,
      label: <div className="text-center w-5">{i18n.t('discount')}</div>
    },
  ];

  const onTabClick = (key: string) => {
    setParams({
      ...params,
      PageIndex: 1
    })
    setActiveKey(key);
    updateTotal(key);
  }

  const updateTotal = (key: any) => {
    switch (key) {
      case TAB.代理:
        setTotal({
          key: 1,
          amount: agentList?.TotalAmount,
          count: agentList?.TotalCount,
        })
        break;
      case TAB.會員:
        setTotal({
          key: 1,
          amount: memberList?.TotalAmount,
          count: memberList?.TotalCount,
        })
        break;
      case TAB.優惠:
        setTotal({
          key: 1,
          amount: categoryList?.TotalAmount,
          count: categoryList?.TotalCount,
        })
        break;
    }
  }

  const searchAgent = (agent: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      AgentAccount: agent.Account,
      MemberAccount: null,
      PageIndex: 1,
    })
    form.setFieldsValue({
      AgentAccount: agent.Account,
      MemberAccount: null,
      BonusId: null,
    });
  };

  const searchMember = (member: any, tab: any) => {
    setActiveKey(tab);
    setParams({
      ...params,
      AgentAccount: null,
      MemberAccount: member.Account,
      PageIndex: 1,
    })
    form.setFieldsValue({
      AgentAccount: null,
      MemberAccount: member.Account,
      BonusId: null,
    });
  };

  const [total, setTotal] = useState({
    key: 1,
    count: 0,
    amount: 0
  });

  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);
  const [initDate, setInitDate] = useState<boolean>(false);
  const [params, setParams] = useState<any>({
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1]),
    PageIndex: 1,
    PageSize: 10,
  });
  const { data: agentList, mutate: mutateAgent, isValidating: agentValidating } = $get({
    url: 'api/report/bonusstat/agent/list',
    params,
  })
  const { data: memberList, mutate: mutateMember, isValidating: memberValidating } = $get({
    url: 'api/report/bonusstat/member/list',
    params
  })
  const { data: categoryList, mutate: mutateCategory, isValidating: categoryValidating } = $get({
    url: 'api/report/bonusstat/category/list',
    params
  })
  useEffect(() => {
    updateTotal(activeKey)
  }, [agentList, memberList, categoryList]);

  const search = (formData: any) => {
    setParams({
      StartDate: timeL2S(date[0]),
      EndDate: timeL2S(date[1]),
      PageIndex: 1,
      PageSize: params.PageSize,
      AgentAccount: formData.AgentAccount,
      MemberAccount: formData.MemberAccount,
      BonusId: formData.BonusId,
    })
    mutateAgent()
    mutateMember()
    mutateCategory()
  }

  const onClear = () => {
    form.resetFields();
    setParams({
      ...params,
      PageIndex: 1,
      PageSize: 10,
    })
    setInitDate(!initDate);
  }

  const searchSpan = $mobile ? 24 : 6

  return (
    <>
      <LayoutNav />
      <Content>
        <Form className='form-search' form={form} onFinish={search}>
          <Row gutter={[12, 12]} style={{ maxWidth: '800px' }}>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col span={searchSpan}>
                  <Form.Item name="BonusId">
                    <Select
                      placeholder={i18n.t("promotionCategory")}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...enumToOptions(BONUS_TYPE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={searchSpan}>
                  <Form.Item name="AgentAccount">
                    <Input placeholder={`${i18n.t('agentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col span={searchSpan}>
                  <Form.Item name="MemberAccount">
                    <Input placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <DatePickerCol span={searchSpan} initDate={initDate} date={date} setDate={setDate}
              rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            {/* 按鈕 */}
            <Col span={24}>
              <Row justify={$mobile ? 'end' : 'start'} gutter={[12, 12]}>
                <Col>
                  <Button type="primary" onClick={form.submit}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <Row align="middle" className="mt-1" gutter={[0, 18]}>
          {/* 總計 */}
          <Col span={24}>
            <Tabs centered={$mobile} items={items} activeKey={activeKey} onTabClick={onTabClick} />
            <Table
              size="middle"
              dataSource={[total]}
              columns={[
                {
                  dataIndex: 'count',
                  title: i18n.t('totalPromotionCount'),
                  align: 'center',
                  width: '50%',
                  render: (val) => $f(val, 0)
                },
                {
                  dataIndex: 'amount',
                  title: i18n.t('totalPromotionAmount'),
                  align: 'center',
                  width: '50%',
                  render: (val) => $f(val)
                },
              ]}
              pagination={false}
            />
          </Col>
          <Col span={24}>
            {/* 代理 */}
            {activeKey === TAB.代理 &&
              <Table
                size="middle"
                scroll={{ x: 400 }}
                loading={agentValidating}
                dataSource={agentList?.Data.map((item: any) => ({ key: item.id, ...item }))}
                columns={[
                  {
                    dataIndex: 'Account',
                    title: i18n.t('agentAccount'),
                    fixed: 'left',
                    width: 150,
                    render: (val, { Level, Account }) => {
                      const word = agentLevelWord(Level);
                      return `${word}／${Account}`
                    }
                  },
                  {
                    dataIndex: 'up_count',
                    title: i18n.t('teamPromotionCount'),
                    align: 'right',
                    width: 150,
                    // sorter: (a, b) => a.teamPromotion - b.teamPromotion,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'up_amount',
                    title: i18n.t('teamPromotionAmount'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamPromotionAmount - b.teamPromotionAmount,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'bonus_count',
                    title: i18n.t('promotionCount'),
                    align: 'right',
                    width: 150,
                    // sorter: (a, b) => a.promotionTotal - b.promotionTotal,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'bonus_amount',
                    title: i18n.t('promotionAmount'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.promotionAmount - b.promotionAmount,
                    render: (val) => $f(val)
                  },
                  {
                    title: i18n.t('operation'),
                    width: 150,
                    render: (val, record) => (
                      <>
                        {info.Account === record.Account &&
                          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.會員)}>
                            {i18n.t('viewMember')}
                          </Button>}
                        {info.Account === record.Account && (
                          <Button className="size-12" type="link" onClick={() => searchAgent(record, TAB.優惠)}>
                            {i18n.t('viewPromotion')}
                          </Button>
                        )}
                      </>
                    )
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: agentList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            }
            {/* 會員 */}
            {activeKey === TAB.會員 &&
              <Table
                size="middle"
                scroll={{ x: 400 }}
                loading={memberValidating}
                dataSource={memberList?.Data.map((item: any) => ({ key: item.id, ...item }))}
                columns={[
                  {
                    dataIndex: 'Account',
                    title: i18n.t('memberAccount'),
                    fixed: 'left',
                    width: 150,
                  },
                  {
                    dataIndex: 'up_count',
                    title: i18n.t('teamPromotionCount'),
                    align: 'right',
                    width: 150,
                    // sorter: (a, b) => a.teamPromotion - b.teamPromotion,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'up_amount',
                    title: i18n.t('teamPromotionAmount'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamPromotionAmount - b.teamPromotionAmount,
                    render: (val) => $f(val)
                  },
                  {
                    dataIndex: 'bonus_count',
                    title: i18n.t('promotionCount'),
                    align: 'right',
                    width: 150,
                    // sorter: (a, b) => a.promotionTotal - b.promotionTotal,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'bonus_amount',
                    title: i18n.t('promotionAmount'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.promotionAmount - b.promotionAmount,
                    render: (val) => $f(val)
                  },
                  {
                    title: i18n.t('operation'),
                    width: 90,
                    render: (_, record) => (
                      <Button className="size-12" type="link" onClick={() => searchMember(record, TAB.優惠)}>
                        {i18n.t('viewPromotion')}
                      </Button>
                    )
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: memberList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            }
            {/* 優惠 */}
            {activeKey === TAB.優惠 &&
              <Table
                size="middle"
                scroll={{ x: 400 }}
                loading={categoryValidating}
                dataSource={categoryList?.Data.map((item: any) => ({ key: item.bonus_name, ...item }))}
                columns={[
                  {
                    dataIndex: 'bonus_name',
                    title: i18n.t('promotionName'),
                    fixed: 'left',
                    width: 150,
                    render: (val) => i18n.t(val)
                  },
                  {
                    dataIndex: 'bonus_count',
                    title: i18n.t('promotionCount'),
                    align: 'right',
                    width: 150,
                    // sorter: (a, b) => a.teamPromotion - b.teamPromotion,
                    render: (val) => $f(val, 0)
                  },
                  {
                    dataIndex: 'bonus_amount',
                    title: i18n.t('promotionAmount'),
                    align: 'right',
                    width: 200,
                    // sorter: (a, b) => a.teamPromotionAmount - b.teamPromotionAmount,
                    render: (val) => $f(val)
                  },
                ]}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
                  total: categoryList?.TotalRecord,
                  current: params.PageIndex,
                  pageSize: params.PageSize
                }}
                onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
              />
            }
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default PageMain;
